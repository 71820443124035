import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plans-card"},[_c('h4',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.name)+" "),_vm._t("plus")],2),_c('span',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.price))]),_c('div',{staticClass:"hide-mobile"},[_c('ul',{staticClass:"features"},_vm._l((_vm.features),function(feature,i){return _c('li',{key:i,style:({
          color:
            feature.type == 'boolean' && feature.value == '0'
              ? '#9AA5B0'
              : '#57697b',
        })},[_vm._v(" "+_vm._s(feature.text)+" "),(!(feature.type == 'boolean' && feature.value == '0'))?_c(VIcon,{staticClass:"icon",attrs:{"size":"16"}},[_vm._v(" $vuetify.icons.checkIcon ")]):_vm._e()],1)}),0)]),_c('div',{staticClass:"hide"},[_c(VExpandTransition,[_c('div',[_c('ul',{staticClass:"features"},_vm._l((_vm.features.slice(0, 5)),function(feature,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(feature.text)+" "),(!(feature.type == 'boolean' && feature.value == '0'))?_c(VIcon,{staticClass:"icon",attrs:{"size":"16"}},[_vm._v(" $vuetify.icons.checkIcon ")]):_vm._e()],1)}),0)])]),_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.expand),expression:"!expand"}]},[_c('ul',{staticClass:"features"},_vm._l((_vm.features.slice(5)),function(feature,i){return _c('li',{key:i,staticClass:"features_list",style:({
              color:
                feature.type == 'boolean' && feature.value == '0'
                  ? '#9AA5B0'
                  : '#57697b',
            })},[_vm._v(" "+_vm._s(feature.text)+" "),(!(feature.type == 'boolean' && feature.value == '0'))?_c(VIcon,{staticClass:"icon",attrs:{"size":"16"}},[_vm._v(" $vuetify.icons.checkIcon ")]):_vm._e()],1)}),0)])])],1),_c('div',{staticClass:"mobile"},[_c('button',{staticClass:"d-flex justify-content-center align-items-center transparent mx-auto my-2",on:{"click":function($event){return _vm.expandCard()}}},[_c('span',{staticClass:"view-more mt-2"},[_vm._v(_vm._s(_vm.expand ? "View More" : "Show Less"))]),_c(VIcon,{staticClass:"ml-2 mt-2",style:({ transform: _vm.expand ? "rotate(360deg)" : "rotate(180deg)" }),attrs:{"size":"17"}},[_vm._v(" $vuetify.icons.doubleArrowDown ")])],1)]),_vm._t("planButton",function(){return [_vm._v(_vm._s(_vm.chooseButton))]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }