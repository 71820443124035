import { Request, Map } from '@/services/http/request';

const request = new Request(process.env.VUE_APP_BACKEND_PORTAL);


export interface FormEntry {
    phone?: string;
    volume?:any;
    service_id?: any;
}

export class SetServiceOrdersVolume {
    static uri: string = "/user/service-volume";
    static send(data: FormEntry) {
        const userToken = localStorage.getItem('userToken') ?? '';
        return request.withCredentials(userToken).post(SetServiceOrdersVolume.uri, data);
    }
}
