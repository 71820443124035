import { Request, Map } from '@/services/http/request';

const request = new Request(process.env.VUE_APP_BACKEND_PORTAL);

export interface FormEntry {
    phone?: string;
    desc?: string;
}

export class GetServiceOrderVolumeOptions {
    static uri: string = "/user/service-volume/options";
    static send() {
        const userToken = localStorage.getItem('userToken') ?? '';
        return request.withCredentials(userToken).get(GetServiceOrderVolumeOptions.uri);
    }
}
