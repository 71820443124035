

























































































































import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import { metadata } from "@/plugins/metadata";
import { types } from "@/services/westore/enums";
import PlanCard from "@/components/planCard.vue";
import { GetPlans } from "@/integration/get-plans";
import { GetServiceOrderVolumeOptions } from "@/integration/get-service-orders-volume-options";
import { SetServiceOrdersVolume } from "@/integration/set-service-orders-volume";
export default mixins(MyMixin).extend({
  name: "Plans",
  mounted() {
    this.getPlans();
    this.getOptions();
    this.setDefaultPricing();
  },
  metaInfo: {
    title: metadata.Plans.title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      { name: "robots", content: "follow, index" },
      {
        name: "description",
        content: metadata.Plans.description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  computed: {
    types() {
      return types;
    },
  },
  components: { PlanCard },
  data: () => ({
    loading: true,
    skeltoneLoad: true,
    selected: "",
    ordersVolumeOptions: [],
    items: [] as {
      name: string;
      features: any[];
      price: number; // Assuming price is a number, adjust it based on your actual data
      id: number;
    }[],
  }),
  methods: {
    setDefaultPricing() {
      let pricing;
      if ((pricing = this.$route.params.pricing)) {
        this.selected = pricing;
      }
    },

    getPlans() {
      GetPlans.get()
        .then(({ data }) => {
          if (data.data.length == 0) {
            this.$router.push({ name: "leads-thank-you" });
          }
          this.items = data.data.map(
            (item: { name: string; features: any[]; id: number }) => {
              return {
                name: item.name,
                features: item.features,
                price: item.features[0].value,
                id: item.id,
              };
            }
          );
        })
        .catch(() => {
          this.$toast.error("something went wrong please try again!");
        })
        .finally(() => ((this.loading = false), (this.skeltoneLoad = false)));
    },

    getOptions() {
      GetServiceOrderVolumeOptions.send().then(({ data }) => {
        this.ordersVolumeOptions = data.data.orders_volume;
      });
    },

    updateOrdersVolume() {
      SetServiceOrdersVolume.send({
        volume: this.selected,
      });
    },

    onChange() {
      console.log("The new value is: ", this.selected);
      this.$router.replace({
        name: "choose-plans",
        params: { pricing: this.selected },
      });
      this.getPlans();
      this.updateOrdersVolume();
    },

    choosePlan(plan: string | number, name: string) {
      this.$router.push({
        name: "confirm-plan",
        params: { plan: plan.toString() },
      });
      this.logGAEvent("Plan", name, (this.$route.name as string) + " Page");
    },
  },
});
