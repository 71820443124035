import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"choose-plan"},[_c(VContainer,[_c(VRow,[_c(VCol,{staticClass:"mx-auto",attrs:{"cols":"12"}},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/leads/Logo.svg"),"alt":"logo"}})]),_c(VCard,{staticClass:"leads-card py-5"},[_c('div',{staticClass:"back-btn",on:{"click":function($event){return _vm.$router.push('/leads-service-orders-volume')}}},[_c(VIcon,{attrs:{"size":"12"}},[_vm._v("$vuetify.icons.chevronLeftIcon")]),_c('span',[_vm._v("Back")])],1),_c('h3',[_vm._v("Choose your plan")]),(_vm.skeltoneLoad)?_c(VRow,{staticClass:"d-flex justify-content-center align-items-center h-100"},[_c(VProgressCircular,{attrs:{"loading":_vm.loading,"color":"ws-pink","size":40,"width":5,"indeterminate":"","rounded":"","height":"10"}})],1):_vm._e(),(!_vm.skeltoneLoad)?_c(VRow,{staticClass:"w-100 d-flex justify-content-center"},[_c(VCol,{staticClass:"px-0",attrs:{"cols":"11","lg":"8"}},[_c('b-form-select',{style:({
                  color: _vm.selected != null ? '#48BEEA' : '#C6C9CC',
                }),attrs:{"options":_vm.ordersVolumeOptions},on:{"change":function($event){return _vm.onChange()}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Choose No. of orders")])]},proxy:true}],null,false,3188418206),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):_vm._e(),(!_vm.skeltoneLoad)?_c(VRow,{staticClass:"w-100 d-flex justify-content-center mt-lg-3 mt-0 mb-lg-7 px-lg-8 px-4 mb-1"},_vm._l((_vm.items),function(item){return _c(VCol,{key:item.id,staticClass:"plan-card mb-lg-15",attrs:{"cols":"12","lg":"4"}},[_c('PlanCard',{attrs:{"name":item.name,"price":item.price,"features":item.features},scopedSlots:_vm._u([(item.name.includes('Plus'))?{key:"plus",fn:function(){return [_c(VIcon,{staticClass:"mb-1 ml-1",attrs:{"size":"20"}},[_vm._v("$vuetify.icons.starIcon")]),_c('img',{staticClass:"ribbon-right",attrs:{"src":require("../../assets/leads/ribbon.svg"),"alt":"ribbon"}})]},proxy:true}:null,{key:"planButton",fn:function(){return [(!item.name.includes('Plus'))?_c('b-button',{staticClass:"choose-btn mb-4 mt-2 btn-outline-info bg-transparent mobile",on:{"click":function($event){return _vm.choosePlan(_vm.items[0].id, _vm.items[0].name)}}},[_vm._v("Choose "+_vm._s(_vm.items[0].name))]):_vm._e(),(item.name.includes('Plus'))?_c('b-button',{staticClass:"choose-btn mb-4 mt-2 pink mobile",on:{"click":function($event){return _vm.choosePlan(_vm.items[1].id, _vm.items[1].name)}}},[_vm._v("Choose "+_vm._s(_vm.items[1].name))]):_vm._e(),_c(VCol,{staticClass:"desk-btn",attrs:{"cols":"12"}},_vm._l((_vm.items),function(item){return _c('b-button',{key:item.id,staticClass:"choose-btn mb-0 mt-2",on:{"click":function($event){return _vm.choosePlan(item.id, item.name)}}},[_vm._v("Choose "+_vm._s(item.name))])}),1)]},proxy:true}],null,true)})],1)}),1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }