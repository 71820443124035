<template>
  <div class="plans-card">
    <h4 class="title">
      {{ name }}
      <slot name="plus"></slot>
    </h4>

    <span class="price"> {{ price }}</span>
    <div class="hide-mobile">
      <ul class="features">
        <li
          v-for="(feature, i) in features"
          :key="i"
          :style="{
            color:
              feature.type == 'boolean' && feature.value == '0'
                ? '#9AA5B0'
                : '#57697b',
          }"
        >
          {{ feature.text }}
          <v-icon
            size="16"
            class="icon"
            v-if="!(feature.type == 'boolean' && feature.value == '0')"
          >
            $vuetify.icons.checkIcon
          </v-icon>
        </li>
      </ul>
    </div>

    <div class="hide">
      <v-expand-transition>
        <div>
          <ul class="features">
            <li v-for="(feature, i) in features.slice(0, 5)" :key="i">
              {{ feature.text }}
              <v-icon
                size="16"
                class="icon"
                v-if="!(feature.type == 'boolean' && feature.value == '0')"
              >
                $vuetify.icons.checkIcon
              </v-icon>
            </li>
          </ul>
        </div>
      </v-expand-transition>

      <v-expand-transition>
        <div v-show="!expand">
          <ul class="features">
            <li
              class="features_list"
              v-for="(feature, i) in features.slice(5)"
              :key="i"
              :style="{
                color:
                  feature.type == 'boolean' && feature.value == '0'
                    ? '#9AA5B0'
                    : '#57697b',
              }"
            >
              {{ feature.text }}
              <v-icon
                size="16"
                class="icon"
                v-if="!(feature.type == 'boolean' && feature.value == '0')"
              >
                $vuetify.icons.checkIcon
              </v-icon>
            </li>
          </ul>
        </div>
      </v-expand-transition>
    </div>
    <div class="mobile">
      <button
        class="d-flex justify-content-center align-items-center transparent mx-auto my-2"
        @click="expandCard()"
      >
        <span class="view-more mt-2">{{
          expand ? "View More" : "Show Less"
        }}</span>
        <v-icon
          size="17"
          class="ml-2 mt-2"
          :style="{ transform: expand ? `rotate(360deg)` : `rotate(180deg)` }"
        >
          $vuetify.icons.doubleArrowDown
        </v-icon>
      </button>
    </div>
    <slot name="planButton">{{ chooseButton }}</slot>
  </div>
</template>
<script>
export default {
  data: () => ({ expand: true }),
  props: ["price", "name", "features"],
  methods: {
    expandCard() {
      this.expand = !this.expand;
    },
  },
};
</script>

<style lang="scss" scoped>
li:first-of-type {
  display: none;
}
.hide {
  display: none;
}
.hide-mobile {
  display: block;
}
@media screen and (max-width: 768px) {
  .hide {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
}
</style>
