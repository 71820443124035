import { Request, Map } from '@/services/http/request';

const request = new Request(process.env.VUE_APP_BACKEND_PORTAL);

export interface PlanItem {
    id?: number;
    name?: string;
    features?: any[];
    price?: number; // Make 'price' property optional
  }
export class GetPlans {
    static uri: string = "/user/plans";
    static get() {
        const userToken = localStorage.getItem('userToken') ?? '';
        return request.withCredentials(userToken).get(GetPlans.uri);
    }
}
